import React from 'react'
import Helmet from 'react-helmet'
import Content from '../Content'
import PropTypes from 'prop-types'
import ProfileCards from '../ProfileCards'
import '@brainhubeu/react-carousel/lib/style.css';


const InfoPageTemplate = ({ title, subtitle, meta_title, meta_description, content, contentComponent, offerings }) => {
  const PageContent = contentComponent || Content

  return (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-primary is-bold is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h1 className='title'>
                  {title}
                </h1>
                <h2 className='subtitle'>
                  {subtitle}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section3'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                  <h5 className="infoText">     
                    <PageContent className='content' content={content} />
                  </h5>
                </div>
              </div>          
            </div>
          </div>
      </section>
      {(title != "Hudson Valley Volunteer" && title != "Protecting Protestors" && title != "Privacy Policy" && title != "Terms and Conditions") && 
      <section className='section3'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h5 className="styledHeader is-centered"> Examples.</h5>
                <br></br>
                <ProfileCards gridItems={offerings.blurbs} className={"column is-4"}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
  </div>
  )
}

InfoPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

export default InfoPageTemplate
