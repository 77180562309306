import React from 'react'
import PropTypes from 'prop-types'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './index.css'



const ProfileCards = ({ gridItems, className }) => {
  if(typeof window !== 'undefined' || typeof document !== 'undefined'){
    if (window.location.pathname.indexOf('admin') > 0) {
      AOS.init({
        disable: false,
      });
    } else AOS.init({disable: false});
  }
  return (
    <div className='columns is-multiline is-centered'>
      {gridItems.map(item => (
        <div key={item.image} className={className} style={{ borderRadius: '5px' }}>
          <div className='card'>
            <div className='card-image'>
              <figure className='image'>
                <img className='card-img' src={item.image} alt={item.title} />
              </figure>
            </div>
            <div className='card-content profile'>
              <div className='media'>
                <div className='media-content'>
                  <p className='title is-4'>{item.title}</p>
                  <p className='subtitle is-6'>{item.subtitle}</p>
                </div>
              </div>
              <div className='content' id='cardcontent'>
                {item.text}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

ProfileCards.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  ),
}

export default ProfileCards
